// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';

const Data = {
  pageName: 'Afkast Test Page',
  pageTemplate: 'FullWidth',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    pageTitle: 'Sampension',
    pageDescription: 'Sampensions web site',
    disableScroll: false,
  },
  pageContent: [
    {
      componentName: 'Iframe',
      componentData: {
        url:
          'https://classic.sampension.dk/Forside/kunde/afkast-og-omkostninger/din-aargangspulje',
      },
    },
  ],
};

export default Data;
